'use client'

import { useState, useEffect, Children } from 'react'
import { BodyObjectView } from 'utility/utility'

import styles from './ComponentBodyContentBlockList.module.scss'
import RightArrow from 'public/icons/arrow-right-thin.svg'

export const Slideshow: BodyObjectView<{
    blocks: React.ReactNode,
    displayTime: number | null
}> =
    ({
        blocks = [],
        displayTime
    }): JSX.Element => {

        const [autoCycle, setAutoCycle] = useState(1)
        const [[page], setPage] = useState([0, 0])

        const paginate = (newDirection: number): void =>
            setPage([page + newDirection, newDirection])

        const blockIndex = ( ( page % Children.count(blocks) ) + Children.count(blocks) ) % Children.count(blocks)

        useEffect( () => {
            const timer = setTimeout( () => {
                if ( autoCycle !== 0 ) {
                    paginate(autoCycle)
                }
            }, ( displayTime ?? 5 ) * 1000 )
            return () => clearTimeout(timer)
        })

        const moveLeft = (): void => {
            setAutoCycle(0)
            paginate(-1)
        }

        const moveRight = (): void => {
            setAutoCycle(0)
            paginate(1)
        }

        return (
            <div className={styles.Slideshow}>
                <ul key='blocks'>
                    {
                        Children.map(blocks, (block, index) =>
                            <li
                                key={index}
                                data-current={ index === blockIndex }
                            >
                               {block}
                            </li>
                        )
                    }
                </ul>
                <button
                    className={styles.ControlLeft}
                    onClick={moveLeft}
                    title='Move left'>
                    <RightArrow/>
                </button>
                <button
                    className={styles.ControlRight}
                    onClick={moveRight}
                    title='Move right'>
                    <RightArrow/>
                </button>
            </div>
        )

    }
