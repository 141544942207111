import { FC, PropsWithChildren } from 'react'

import styles from './SectionHeader.module.scss'

type SectionHeaderData = {
    className?: string
}

export const SectionHeader: FC<PropsWithChildren<SectionHeaderData>> =
    ({
        children,
        className = ''
    }) =>
        <header className={`${styles.Header} ${className}`}>
            {children}
        </header>
