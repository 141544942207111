import { FC } from 'react'

type ChangeHandler = (value: string) => void

type TimeFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string
}

export const TimeFilter: FC<TimeFilterArgs> =
    ({
        value,
        onChange,
        className
    }) =>
        <span className={className}>
            <label>Time</label>
            <select
                value={value ?? ''}
                onChange={
                    ({target:{value}}): void =>
                        onChange(value)
                }
                title='Time'
            >
                <option value='upcoming'>Upcoming</option>
                <option value='recent'>Recent</option>
            </select>
            </span>
