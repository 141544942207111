'use client'
import { BodyObjectView, key } from 'utility/utility'
import { ComponentBodySessionListFragment } from 'generated/graphql'
import { useSearchParams } from 'next/navigation'

import { SessionFeatured } from 'content-types/Session/Session.Featured/Session.Featured'
import { Theme } from 'content-types/Theme/Theme'
import { TheatreFilters } from './TheatreFilters'
import { Markdown } from 'components/shared/Markdown/Markdown'
import { SectionHeader } from 'components/shared/SectionHeader/SectionHeader'

import styles from './ComponentBodySessionList.module.scss'

export const ComponentBodySessionList: BodyObjectView<ComponentBodySessionListFragment> =
    ({
        text,
        theme,
        event,
        siteContext,
        pageContext,
        articleContext,
        ...object
    }) => {

        const searchParams = useSearchParams()

        const taggedSessions = event?.data?.attributes?.sessions?.data ?? []
        const parentEventSessions = event?.data?.attributes?.parent_event?.data?.attributes?.sessions?.data ?? []
        const childEventsSessions = event?.data?.attributes?.child_events?.data?.map(event => event.attributes?.sessions?.data) ?? []
        const siblingEventsSessions = event?.data?.attributes?.parent_event?.data?.attributes?.child_events?.data?.map(event => event.attributes?.sessions?.data) ?? []
        
        const sessions = [
            ...taggedSessions.map( ({attributes}) => attributes ),
            ...parentEventSessions.map( ({attributes}) => attributes ),
            ...childEventsSessions.flat().map( session => session?.attributes ),
            ...siblingEventsSessions.flat().map( session => session?.attributes )
        ]

        const theatres = [...new Set(sessions.map(session => session?.theatre?.data?.attributes?.title))]

        return (
            <section
                id={key(object)}
                className={styles.SessionList}
                style={ Theme(theme?.data?.attributes) }
            >
                {
                    text === null || text === '' ? <></> :
                        <SectionHeader>
                            <Markdown
                                siteContext={siteContext}
                                pageContext={pageContext}
                                articleContext={articleContext}
                            >
                                {text}
                            </Markdown>
                        </SectionHeader>
                }
                {theatres.length > 1 ? <TheatreFilters theatres={theatres} /> : <></>}
                <ul data-number={sessions.length} className={styles.Sessions}>
                    {
                        sessions
                            .map( session =>
                                session === null || session === undefined || 
                                 (searchParams.get("theatre") !== null && session.theatre?.data?.attributes?.title !== searchParams.get("theatre")) ? <></> :
                                    <li key={session.name}>
                                        <SessionFeatured
                                            {...session}
                                        />
                                    </li>
                            )
                    }
                </ul>
            </section>
        )

    }
