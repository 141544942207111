'use client'

import { produce } from 'immer'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'
import { FC } from 'react'
import styles from './ComponentBodySpeakerList.module.scss'

export const EventFilters: FC<{events: (string|null|undefined)[]}> =
    ({
        events
    }) => {

        const router = useRouter()
        const pathname = usePathname()
        const searchParams = useSearchParams()

        const updateQuery = (
            value: string
        ): void => {
            const field = "event"
            const params = produce(Object.fromEntries(searchParams.entries()), draft => {
                if ( value === 'all' || value === searchParams.get(field) ) {
                    delete draft[field]
                }
                else {
                    draft[field] = value
                }
            })
            const query = new URLSearchParams([...Object.entries(params)])
            router.replace(
                `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
                { scroll: false }
            )
        }

        const eventNames = events.filter(event => event !== null && event !== undefined)
        return (
            <ul data-number={eventNames.length} className={styles.Events}>
                    <li key="all">
                        <button onClick={() => updateQuery("all")}>
                            All Events
                        </button>
                    </li>
                    {
                        events
                            .map( event =>
                                event === null || event === undefined ? <></> :
                                    <li key={event}>
                                        <button onClick={() => updateQuery(event)}>
                                            {event}
                                        </button>
                                    </li>
                            )
                    }
                </ul>
        )
    }