import { FC } from 'react'
import { AllTopicsQuery } from 'generated/graphql'
import { type PageContext } from 'context/PageContext'

type ChangeHandler = (value: string) => void

type TopicFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    allTopics: AllTopicsQuery
    portfolio: string,
    pageContext: PageContext
}

export const TopicFilter: FC<TopicFilterArgs> =
    ({
        value,
        onChange,
        allTopics: { allTopics },
        portfolio = '0',
        className
    }) => {

        const topics =
            portfolio === '0' || portfolio === '' ? allTopics?.data :
                allTopics?.data?.filter( topic =>
                    topic.attributes?.portfolio?.data.find(
                        p => p.attributes?.slug === portfolio
                    )
                )

        return (
            <span className={className}>
                <label>Topic</label>
                <select
                    value={value ?? ''}
                    onChange={
                        ({target:{value}}): void =>
                            onChange(value)
                    }
                    title='Topic'
                >
                    <option value='all'>All</option>
                    {
                        topics?.map( ({attributes}) =>
                            <option key={attributes?.slug} value={attributes?.slug}>
                                {attributes?.title}
                            </option>
                        )
                    }
                </select>
            </span>

        )

    }

