import { FC } from 'react'
import { SessionFeaturedFragment } from 'generated/graphql'

import styles from './Session.Featured.module.scss'
import { SpeakerFeatured } from 'content-types/Speaker/Speaker.Featured/Speaker.Featured'

export const SessionFeatured: FC<SessionFeaturedFragment> = ({
    speakers,
    name,
    theatre,
    type,
    datetime
}) =>
    <div className={styles.SessionList}>
        <h2>{type?.replace("_", " ")}</h2>
        <h3>{name}</h3>
        {datetime ? <p>{new Date(datetime).toLocaleString("en-CA")}</p> : <></>}
        <p>{theatre?.data?.attributes?.title}</p>
        {
            speakers === null ? <></> :
            <section className={styles.Speakers}>
                <ul data-number={speakers.data.length}>
                    {
                        speakers.data
                            .map( speaker =>
                                speaker.attributes === null || speaker.attributes === undefined ? <></> :
                                    <li key={speaker.attributes?.name}>
                                        <SpeakerFeatured
                                            {...speaker.attributes}
                                        />
                                    </li>
                            )
                    }
                </ul>
            </section>
        }
        
    </div>
