'use client'

import { ContentTypeView, Sizes } from 'utility/utility'
import { Markdown } from 'components/shared/Markdown/Markdown'
import { NewsletterSummaryFragment } from 'generated/graphql'
import { NewsletterLink } from 'content-types/Newsletter/Newsletter.Link/Newsletter.Link'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { MetadataTag } from 'components/shared/MetadataTag/MetadataTag'
import { Summary } from 'components/shared/Summary/Summary'

export const NewsletterSummary: ContentTypeView<NewsletterSummaryFragment> = ({
    picture,
    siteContext,
    pageContext,
    articleContext,
    summary,
    ...newsletter
}) =>
    <Summary
        image={
            picture === undefined || picture.image === null ? <></> :
                <NewsletterLink
                    siteContext={siteContext}
                    {...newsletter}
                >
                    <ComponentImagePicture
                        {...picture}
                        sizes={Sizes.Summary}
                    />
                </NewsletterLink>
        }
        header={
            <>
                <MetadataTag>
                    Newsletter
                </MetadataTag>
                <h1>
                    <NewsletterLink
                        siteContext={siteContext}
                        {...newsletter}
                    >
                        { newsletter.title }
                    </NewsletterLink>
                </h1>
            </>
        }
        summary={
            <Markdown
                siteContext={siteContext}
                pageContext={pageContext}
                articleContext={articleContext}
            >
                {summary ?? ''}
            </Markdown>
        }
    />
