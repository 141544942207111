'use client'

import { produce } from 'immer'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'
import { FC } from 'react'
import styles from './ComponentBodySessionList.module.scss'

export const TheatreFilters: FC<{theatres: (string|null|undefined)[]}> =
    ({
        theatres
    }) => {

        const router = useRouter()
        const pathname = usePathname()
        const searchParams = useSearchParams()

        const updateQuery = (
            value: string
        ): void => {
            const field = "theatre"
            const params = produce(Object.fromEntries(searchParams.entries()), draft => {
                if ( value === 'all' || value === searchParams.get(field) ) {
                    delete draft[field]
                }
                else {
                    draft[field] = value
                }
            })
            const query = new URLSearchParams([...Object.entries(params)])
            router.replace(
                `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
                { scroll: false }
            )
        }

        const theatreNames = theatres.filter(theatre => theatre !== null && theatre !== undefined)
        return (
            <ul data-number={theatreNames.length} className={styles.Theatres}>
                    <li key="all">
                        <button onClick={() => updateQuery("all")}>
                            All Theatres
                        </button>
                    </li>
                    {
                        theatres
                            .map( theatre =>
                                theatre === null || theatre === undefined ? <></> :
                                    <li key={theatre}>
                                        <button onClick={() => updateQuery(theatre)}>
                                            {theatre}
                                        </button>
                                    </li>
                            )
                    }
                </ul>
        )
    }