'use client'

import { SocialMediaChannelFeedFragment } from 'generated/graphql'
import { ContentTypeView } from 'utility/utility'
import { TwitterTimelineEmbed } from 'react-twitter-embed'

export const SocialMediaChannelFeed: ContentTypeView<SocialMediaChannelFeedFragment & {maximumNumber: number}> =
    ({
        service,
        identifier,
        maximumNumber
    }) =>
        service === 'Twitter' ?
            <TwitterTimelineEmbed
                sourceType='profile'
                screenName={identifier}
                noFooter={true}
                transparent={false}
                tweetLimit={maximumNumber}
                options={{
                    width: 400

                }}
            />
        : null
