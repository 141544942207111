import { FC } from 'react'
import { SpeakerFeaturedFragment } from 'generated/graphql'
import { ComponentImagePicture } from 'components/image/ComponentImagePicture/ComponentImagePicture'
import { Sizes } from 'utility/Sizes'

import styles from './Speaker.Featured.module.scss'

export const SpeakerFeatured: FC<SpeakerFeaturedFragment> = ({
    person,
}) =>
    <div className={styles.Speaker}>
        {person?.data?.attributes?.photo ?
            <ComponentImagePicture
                {...person.data.attributes.photo}
                className={styles.Image}
                sizes={Sizes.Featured}
            />
            : <></>
        }
        <div className={styles.SpeakerText}>
            <p>{person?.data?.attributes?.forename} {person?.data?.attributes?.surname}</p>
            <p>{person?.data?.attributes?.jobTitle}</p>
            <p>{person?.data?.attributes?.company?.data?.attributes?.title}</p>
        </div>
        <div className={styles.CompanyLogo}>
            {person?.data?.attributes?.company?.data?.attributes?.picture ?
                <ComponentImagePicture
                    {...person.data.attributes.company.data.attributes.picture}
                    className={styles.Company}
                    sizes={Sizes.Featured}
                />
                : <></>
            }
        </div>
    </div>
