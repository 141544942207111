import { FC } from 'react'

import { EventSummaryFragment } from 'generated/graphql'

type ChangeHandler = (value: string) => void

type EventLocationFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    events: EventSummaryFragment[]
}

export const EventLocationFilter: FC<EventLocationFilterArgs> =
    ({
        value,
        onChange,
        className,
        events
    }) => {

        return (
           <span className={className}>
               <label>Location</label>
               <select
                   value={value ?? ''}
                   onChange={
                       ({target:{value}}): void =>
                           onChange(value)
                   }
                   title='Location'
               >
                   <option value='all'>All</option>
                   {
                       events
                           .map( ({venue}) => venue?.country )
                           .filter( (value, index, self) => value !== undefined && value !== null && self.indexOf(value) === index )
                           .sort( (a,b) => ( a ?? '' ) < ( b ?? '' ) ? -1 : a === b ? 0 : 1 )
                           .map( (country) =>
                               <option key={country} value={country ?? ''}>{country}</option>
                           )
                   }
               </select>
           </span>
        )

    }


