'use client'

import { FC } from 'react'
import Head from 'next/head'
import { UploadFilePreloadFragment } from 'generated/graphql'
import { strapiPublicImage } from 'utility/utility'
import { type Format, type Formats } from '../UploadFile'

const type = (mime: string): string|undefined =>
    mime.split('/')[0]

export const UploadFilePreload: FC<UploadFilePreloadFragment & {rel?: string, formats: Formats | null}> = ({
    mime,
    url,
    formats,
    rel = 'prefetch'
}) => {

    if ( formats !== null ) {

        const sources =
            Object.values(formats as Formats)
                .sort((a: Format, b: Format) => b.width - a.width)

        return (
            <Head>
                {
                    sources.map( ({hash,url,width}, index) =>
                        <link
                            key={hash}
                            rel={rel}
                            as={type(mime)}
                            href={strapiPublicImage(url)}
                            type={mime}
                            media={`(min-width: ${width}px)${ sources[index-1]?.width ? ` and max-width: ${sources[index-1]?.width ?? 1 - 1}px` : '' }`}
                        />
                    )
                }
            </Head>
        )

    }
    else {
        return (
            <Head>
                <link
                    key={url}
                    rel='preload'
                    as={type(mime)}
                    href={strapiPublicImage(url)}
                />
            </Head>
        )
    }

}
