'use client'

import { BodyObjectView } from 'utility/utility'
import { useSession } from 'next-auth/react'

export const ComponentBodyNewsletterIssueListClient: BodyObjectView<{
    content: React.ReactNode
}> =
    /* eslint-disable-next-line  @typescript-eslint/promise-function-async */
    ({ content }) => {

        const { status } = useSession()

        return (
            status === 'authenticated' ? content : <></>
        )

    }
