import { FC } from 'react'
import { EventTypesQuery } from 'generated/graphql'

type ChangeHandler = (value: string) => void

type EventTypeFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    eventTypes: EventTypesQuery
}

export const EventTypeFilter: FC<EventTypeFilterArgs> =
    ({
        value,
        onChange,
        className,
        eventTypes: { eventTypes }
    }) =>
        <span className={className}>
            <label>Type</label>
            <select
                value={value ?? ''}
                onChange={
                    ({target:{value}}): void =>
                        onChange(value)
                }
                title='Event Type'
            >
                <option value='all'>All</option>
                {
                    eventTypes?.enumValues.map( ({name}) =>
                        <option key={name} value={name}>{name}</option>
                    )
                }
            </select>
        </span>
