import { FC } from 'react'

import { AllActivitiesQuery } from 'generated/graphql'

type ChangeHandler = (value: string) => void

type ActivityFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    allActivities: AllActivitiesQuery
}

export const ActivityFilter: FC<ActivityFilterArgs> =
    ({
        value,
        onChange,
        className,
        allActivities: { allActivities }
    }) =>
        <span className={className}>
            <label>Business Area</label>
            <select
                value={value ?? ''}
                onChange={
                    ({target:{value}}): void =>
                        onChange(value)
                }
                title='Business Area'
            >
                <option value='all'>All</option>
                {
                    allActivities?.data.map( ({attributes}) =>
                        <option key={attributes?.slug} value={attributes?.slug}>{attributes?.title}</option>
                    )
                }
            </select>
        </span>
