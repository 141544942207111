'use client' 

import { FC } from 'react'
import { Accordion, AccordionItem } from '@szhsin/react-accordion'
import Markdown from 'react-markdown'
import './AccordionStyles.scss'
import ChevronDown from 'public/icons/chevron-down.svg'

const blockHeader: FC<string> = (title) => 
    <>
        {title}
        <ChevronDown className="chevron-down"/>
    </>


export const AccordionBlock: FC<{blocks: {title: string, content: string}[]}> = ({
    blocks,
}) =>
    <Accordion>
    {
        blocks?.map(block =>
            <AccordionItem key={block.title ?? block.content} header={blockHeader(block.title ?? "test")}>
                <Markdown>{block.content}</Markdown>
            </AccordionItem>
        )
    }
    </Accordion>