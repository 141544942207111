'use client'

import { useState } from 'react'
import { ContentTypeView } from 'utility/utility'

import styles from './CallToAction.CallToAction.module.scss'
import buttonStyles from 'styles/button.module.scss'

export const CallToActionCallToActionClient: ContentTypeView<{
    text: React.ReactNode,
    image: React.ReactNode,
    actions: React.ReactNode,
    formLabel: string,
    form?: React.ReactNode
}> = ({
    text,
    image,
    formLabel,
    form,
    actions
}) => {

    const [formOpen,setFormOpen] = useState(false)

    return (
        <>
            <section className={styles.BodyContent}>
                <section>
                    {text}
                    <ul className={styles.Actions}>
                        {
                            form === undefined ? <></> :
                                <li>
                                    <button
                                        className={buttonStyles.Link}
                                        onClick={ (e) => {
                                            e.preventDefault()
                                            setFormOpen(!formOpen)
                                        }}
                                    >
                                        { formOpen ? 'Close Form' : formLabel }
                                    </button>
                                </li>
                        }
                        {

                        }
                        { actions}
                    </ul>
                </section>
            </section>
            <div className={styles.SecondaryContent}>
                { formOpen ? form : image }
            </div>
        </>
    )

}

