'use client'

import { Suspense } from 'react'
import { produce } from 'immer'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'

import {
    DatabaseSummaryFragment,
    DatasetSummaryFragment,
    InsightReportSummaryFragment,
    MagazineSummaryFragment,
    MarketReportSummaryFragment,
    NewsletterSummaryFragment,
    AllPortfoliosQuery,
    AllTopicsQuery
} from 'generated/graphql'

import { BodyObjectView, FilterableView } from 'utility/utility'

import { Filters } from 'components/shared/Filters/Filters'
import { PublicationTypeFilter } from 'components/filters/PublicationTypeFilter/PublicationTypeFilter'
import { PortfolioFilter } from 'components/filters/PortfolioFilter/PortfolioFilter'
import { TopicFilter } from 'components/filters/TopicFilter/TopicFilter'

import styles from './ComponentBodyPublicationBrowser.module.scss'

type Publication =
    | DatabaseSummaryFragment
    | DatasetSummaryFragment
    | InsightReportSummaryFragment
    | MagazineSummaryFragment
    | MarketReportSummaryFragment
    | NewsletterSummaryFragment

const types: { [key: string]: string } = {
    'database': 'Database',
    'dataset': 'Dataset',
    'insight-report': 'InsightReport',
    'magazine': 'Magazine',
    'market-report': 'MarketReport',
    'newsletter': 'Newsletter'
} as const

export const PublicationFilterList: BodyObjectView<{
    publications: FilterableView<Publication>[],
    allPortfolios: AllPortfoliosQuery,
    allTopics: AllTopicsQuery
}> =
    ({
        publications,
        allPortfolios,
        allTopics,
        pageContext
    }) => {

        const router = useRouter()
        const pathname = usePathname()
        const searchParams = useSearchParams()

        const type      = searchParams.get('type')
        const portfolio = searchParams.get('portfolio')
        const topic     = searchParams.get('topic')

        const updateQuery = (updates: { [key: string]: string } ): void => {
            const params = produce(Object.fromEntries(searchParams.entries()), draft => {
                Object.entries(updates).forEach( ([field,value]) => {
                    if ( value === 'all' ) {
                        delete draft[field]
                    }
                    else {
                        draft[field] = value
                    }
                })
            })
            const query = new URLSearchParams([...Object.entries(params)])
            router.replace(
                `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
                { scroll: false }
            )
        }

        const setType = (type: string): void =>
            updateQuery({type})

        const setPortfolio = (portfolio: string): void => {
            updateQuery({
                portfolio,
                topic: 'all'
            })
        }

        const setTopic = (topic: string): void =>
            updateQuery({topic})

        return (
            <>
                <Filters className={styles.Filters}>
                    <Suspense>
                        <PublicationTypeFilter
                            key='type'
                            value={type}
                            onChange={setType}
                            className={styles.TypeFilter}
                        />
                    </Suspense>
                    <Suspense>
                        <PortfolioFilter
                            key='portfolio'
                            value={portfolio}
                            onChange={setPortfolio}
                            className={styles.PortfolioFilter}
                            allPortfolios={allPortfolios}
                        />
                    </Suspense>
                    <Suspense>
                        <TopicFilter
                            key='topic'
                            value={topic}
                            onChange={setTopic}
                            allTopics={allTopics}
                            portfolio={portfolio ?? ''}
                            className={styles.TopicFilter}
                            pageContext={pageContext}
                        />
                    </Suspense>
                </Filters>
                <ul key='articles'>
                    {
                        publications.map( ({key,metadata,content}) => {

                            const visible =
                                (
                                       type === null
                                    || type === 'all'
                                    || metadata.__typename === types[type]
                                )
                                &&
                                (
                                       portfolio === null
                                    || portfolio === 'all'
                                    || metadata.portfolios?.data.find( p => p.attributes?.slug === portfolio ) !== undefined
                                )
                                &&
                                (
                                       topic === null
                                    || topic === 'all'
                                    || metadata.topics?.data.find( t => t.attributes?.slug === topic ) !== undefined
                                )

                            return (
                                <li key={key} data-visible={visible}>
                                    {content}
                                </li>
                            )

                        })
                    }
                </ul>
            </>
        )

    }

