'use client'

import { Suspense } from 'react'
import { produce } from 'immer'
import { useRouter, usePathname, useSearchParams } from 'next/navigation'

import {
    AllActivitiesQuery,
    AllPortfoliosQuery,
    AllTopicsQuery,
    ArticleTypesQuery,
    ArticleFeaturedFragment
} from 'generated/graphql'

import { BodyObjectView, FilterableView } from 'utility/utility'
import { Filters } from 'components/shared/Filters/Filters'
import { ActivityFilter } from 'components/filters/ActivityFilter/ActivityFilter'
import { ArticleTypeFilter } from 'components/filters/ArticleTypeFilter/ArticleTypeFilter'
import { PortfolioFilter } from 'components/filters/PortfolioFilter/PortfolioFilter'
import { TopicFilter } from 'components/filters/TopicFilter/TopicFilter'

import styles from './ComponentBodyArticleBrowser.module.scss'

export const ArticleFilterList: BodyObjectView<{
    articles: FilterableView<ArticleFeaturedFragment>[],
    allActivities: AllActivitiesQuery,
    allPortfolios: AllPortfoliosQuery,
    allTopics: AllTopicsQuery,
    articleTypes: ArticleTypesQuery
}> =
    ({
        articles,
        allActivities,
        articleTypes,
        allPortfolios,
        allTopics,
        pageContext
    }) => {

        const router = useRouter()
        const pathname = usePathname()
        const searchParams = useSearchParams()

        const type      = searchParams.get('type')
        const activity  = searchParams.get('activity')
        const portfolio = searchParams.get('portfolio')
        const topic     = searchParams.get('topic')

        const updateQuery = (updates: { [key: string]: string } ): void => {
            const params = produce(Object.fromEntries(searchParams.entries()), draft => {
                Object.entries(updates).forEach( ([field,value]) => {
                    if ( value === 'all' ) {
                        delete draft[field]
                    }
                    else {
                        draft[field] = value
                    }
                })
            })
            const query = new URLSearchParams([...Object.entries(params)])
            router.replace(
                `${pathname}${ query.size === 0 ? '' : `?${query.toString()}` }`,
                { scroll: false }
            )
        }

        const setType = (type: string): void =>
            updateQuery({type})

        const setActivity = (activity: string): void =>
            updateQuery({activity})

        const setPortfolio = (portfolio: string): void => {
            updateQuery({
                portfolio,
                topic: 'all'
            })
        }

        const setTopic = (topic: string): void =>
            updateQuery({topic})



        return (
            <>
                <Filters className={styles.Filters}>
                    <Suspense>
                        <ArticleTypeFilter
                            key='article_type'
                            value={type}
                            onChange={setType}
                            className={styles.TypeFilter}
                            articleTypes={articleTypes}
                        />
                    </Suspense>
                    <Suspense>
                        <ActivityFilter
                            key='activity'
                            value={activity}
                            onChange={setActivity}
                            allActivities={allActivities}
                            className={styles.ActivityFilter}
                        />
                    </Suspense>
                    <Suspense>
                        <PortfolioFilter
                            key='portfolio'
                            value={portfolio}
                            onChange={setPortfolio}
                            className={styles.PortfolioFilter}
                            allPortfolios={allPortfolios}
                        />
                    </Suspense>
                    <Suspense>
                        <TopicFilter
                            key='topic'
                            value={topic}
                            onChange={setTopic}
                            allTopics={allTopics}
                            portfolio={portfolio ?? ''}
                            className={styles.TopicFilter}
                            pageContext={pageContext}
                        />
                    </Suspense>
                </Filters>
                <ul key='articles' className={styles.Articles}>
                    {
                        articles.map( ({metadata,content}) =>
                            <li
                                key={metadata.slug}
                                data-visible={
                                    (
                                           type === null
                                        || type === 'all'
                                        || metadata.articleType?.data?.attributes?.title === type
                                    )
                                    &&
                                    (
                                           activity === null
                                        || activity === 'all'
                                        || metadata.activities?.data.find( a => a.attributes?.slug === activity ) !== undefined
                                    )
                                    &&
                                    (
                                           portfolio === null
                                        || portfolio === 'all'
                                        || metadata.portfolios?.data.find( p => p.attributes?.slug === portfolio ) !== undefined
                                    )
                                    &&
                                    (
                                           topic === null
                                        || topic === 'all'
                                        || metadata.topics?.data.find( t => t.attributes?.slug === topic ) !== undefined
                                    )
                                }
                            >
                                { content }
                            </li>
                        )
                    }
                </ul>
            </>
        )

    }

