import { FC } from 'react'

import styles from './Summary.module.scss'

type SummaryData = {
    image: React.ReactNode,
    header: React.ReactNode,
    summary?: React.ReactNode
    className?: string
}

export const Summary: FC<SummaryData> =
    ({
        image,
        header,
        summary,
        className = ''
    }) =>
        <div className={`${styles.Summary} ${className}`}>
            <header className={styles.Header}>
                {header}
            </header>
            <section className={styles.Image}>
                {image}
            </section>
            <section className={styles.Details}>
                {summary}
            </section>
        </div>
