import { FC } from 'react'
import { AllPortfoliosQuery } from 'generated/graphql'

type ChangeHandler = (value: string) => void

type PortfolioFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    allPortfolios: AllPortfoliosQuery
}

export const PortfolioFilter: FC<PortfolioFilterArgs> =
    ({
        value,
        onChange,
        className,
        allPortfolios: { allPortfolios }
    }) =>
        <span className={className}>
            <label>Industry</label>
            <select
                value={value ?? ''}
                onChange={
                    ({target:{value}}): void =>
                        onChange(value)
                }
                title='Industry'
            >
                <option value='all'>All</option>
                {
                    allPortfolios?.data.map( ({attributes}) =>
                        <option key={attributes?.slug} value={attributes?.slug}>
                            {attributes?.title}
                        </option>
                    )
                }
            </select>
        </span>
