'use client'

import { FC, useState } from 'react'

import styles from './Person.Featured.module.scss'

export const Biography: FC<{
    forename: string,
    surname: string,
    biography: React.ReactNode
}> = ({
    forename,
    surname,
    biography
}) => {

    const [biographyOpen, setBiographyOpen] = useState(false)

    return (
        <>
            <a
                href=''
                className={styles.BiographyLink}
                onClick={ (e) => {
                    e.preventDefault()
                    setBiographyOpen(true)
                }}
            >
                More about {forename}
            </a>
            <dialog
                open={biographyOpen}
                className={styles.Biography}
            >
                <div>
                    <h1>{forename} {surname}</h1>
                    { biography }
                </div>
                <a
                    href=''
                    onClick={ (e) => {
                        e.preventDefault()
                        setBiographyOpen(false)
                    }}
                >Close</a>
            </dialog>
        </>
    )

}

