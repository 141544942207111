'use client'

import { FC, useState, useRef, useEffect, Children, PropsWithChildren } from 'react'
import { useInView } from 'react-intersection-observer'

import RightArrow from 'public/icons/arrow-right-thin.svg'
import styles from './SliderList.module.scss'

export const SliderList: FC<PropsWithChildren> = ({children}) => {

    const [ start, setStart ] = useState(0)
    const [ startRef, startInView ] = useInView({threshold: 1})
    const [ endRef, endInView ] = useInView({threshold: 1})

    const ul = useRef<HTMLUListElement>(null)

    useEffect(() => {
        if ( ul.current !== null ) {
            const li = ul.current.children.item(start) as HTMLLIElement | null
            if ( li !== null) {
                const pageGutter = parseInt(getComputedStyle(li).getPropertyValue('--page-padding-horizontal'))
                const offset = li.offsetLeft - ( window.matchMedia('(min-width: 350px)').matches ? pageGutter : 0 )
                ul.current.scrollTo(offset,0)
            }
        }
    },[start])

    const nav = (increment: number): void => {
        setStart(Math.max(Math.min(start+increment,Children.count(children)),0))
    }

    return (
        Children.count(children) === 0 ? <></> :
            <>
                <ul
                    ref={ul}
                    className={styles.List}
                >
                    {
                        Children.map(children, (child,index) =>
                            <li
                                key={index}
                                ref={
                                    index === 0
                                        ? startRef
                                        : index === Children.count(children) - 1
                                            ? endRef
                                            : undefined
                                }
                            >
                                { child }
                            </li>
                        )
                    }
                </ul>
                <nav className={styles.Controls}>
                    <button
                        disabled={ startInView }
                        onClick={(): void => nav(-1)} title='Scroll left'
                    >
                        <RightArrow/>
                    </button>
                    <button
                        disabled={ endInView }
                        onClick={(): void => nav(1)} title="Scroll right"
                    >
                        <RightArrow/>
                    </button>
                </nav>
            </>
    )

}
