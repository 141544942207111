'use client'

import { useSession } from 'next-auth/react'
import { ContentTypeLink, contentType } from 'utility/utility'
import { NewsletterLinkFragment, PageLinkFragment } from 'generated/graphql'

import { PageLink } from 'content-types/Page/Page.Link/Page.Link'

export const NewsletterLink: ContentTypeLink<NewsletterLinkFragment> =
    ({
        children,
        className,
        title,
        subscribedPage,
        unsubscribedPage,
        siteContext
    }) => {

        const { data: session } = useSession()

        const page = session ? contentType(subscribedPage) : contentType(unsubscribedPage) as PageLinkFragment

        return (
            <PageLink
                className={className}
                siteContext={siteContext}
                {...page!}
                title={title}
            >
                { children }
            </PageLink>
        )


    }
