import { FC } from 'react'
import { ArticleTypesQuery } from 'generated/graphql'

type ChangeHandler = (value: string) => void

type ArticleTypeFilterArgs = {
    value: string | null,
    onChange: ChangeHandler,
    className?: string,
    articleTypes: ArticleTypesQuery
}

export const ArticleTypeFilter: FC<ArticleTypeFilterArgs> =
    ({
        value,
        onChange,
        className,
        articleTypes: { articleTypes }
    }) =>
        <span className={className}>
            <label>Type</label>
            <select
                value={value ?? ''}
                onChange={
                    ({target:{value}}): void =>
                        onChange(value)
                }
                title='Article Type'

            >
                <option value='all'>All</option>
                {
                    articleTypes?.data.map( ({attributes}) =>
                        attributes === null ? <></> :
                            <option key={attributes.title} value={attributes.title}>{attributes.title}</option>
                    )
                }
            </select>
        </span>
